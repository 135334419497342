import React, { useEffect, useRef, useState } from 'react';

import { initializeSocket, getSocket } from '../../config/socket';

import { FaPaperPlane } from "react-icons/fa6";
import { Link } from 'react-router-dom';
import { ImageComponent } from '../Image';

export const ChatList = ({ currentUserId, otherUserId, userDetail, messageList }) => {
  const inputRef = useRef(null);
  const chatContainer = useRef(null)

  const { username, photo } = userDetail;

  const [input, setInput] = useState('');
  const [heightInput, setHeightInput] = useState('31px')
  
  const sendMessage = () => {
    const value = input.trim()

    if (!value) {
      setHeightInput('31px')
      setInput('')
      console.log('no input...', value)
      return;
    } 

    const socket = getSocket();

    const message = {
      senderId: currentUserId,
      receiverId: otherUserId,
      message: value,
    };

    socket.emit('sendMessage', message);
    setInput('');
  };

  const onKeyUp = (event) => {
    const key = event.key

    if (key === 'Enter') sendMessage()
  }

  useEffect(() => {
    if (inputRef.current) {
      if (!input) {
        setHeightInput("31px");
      } else {
        setHeightInput("1px"); // Reset tinggi untuk menghitung ulang
        setHeightInput(`${inputRef.current.scrollHeight}px`); // Atur tinggi berdasarkan scrollHeight
      }
    }
  }, [input]);

  useEffect(() => {
    if (otherUserId && messageList.length > 1) {
      if (chatContainer.current) {
        chatContainer.current.scrollTop = chatContainer.current.scrollHeight;
      }
    }
  }, [otherUserId, messageList])

  return (
    <div className='chat-list--container'>
      <div className='user-receiver--container'>
        <div className='user-receiver--info'>
          <div className='img'>
            <ImageComponent src={photo} alt="user receiver photo" />
          </div>
          <div className='info'>
            <Link to={`/user-articles/${username}`}>
              <h4 className='user-name--info'>{username}</h4>
            </Link>
            <span className='user-last-seen--info d-none'>-</span>
          </div>
        </div>
      </div>

      <div className='chat-list--parent' ref={chatContainer}>
          {messageList.length > 0 
            ? messageList.map((group) => (
                <div key={group.date} style={{ display: 'flex', flexDirection: 'column' }}>
                  <h3 className='date-group'>{group.date}</h3>
                  <div className='chat-list'>
                    {group.messages.map((msg, index) => (
                      <div key={index} className={`chat-bubble ${msg.senderId === currentUserId ? 'sent' : 'rcvd'}`}>
                        {msg.message}
                      </div>
                    ))}
                  </div>
                </div>
              )) 
            : <div style={{ textAlign: 'center' }}>No message available...</div>}
      </div>

      <div className='input-chat--container'>
        <textarea
          ref={inputRef}
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Type your message"
          style={{ height: heightInput }}
          onKeyUp={onKeyUp}
        ></textarea>
        <button onClick={sendMessage}>
          <FaPaperPlane width={25} height={25} color='black' />
        </button>
      </div>
    </div>
  );
};
