import { io } from 'socket.io-client';

import { BASE_URL } from './config.js';

let socket;

export const initializeSocket = (userId) => {
  if (socket) socket.disconnect(); // Putuskan koneksi lama jika ada

  socket = io(BASE_URL, {
    query: { userId }, // Tambahkan userId sebagai query parameter
  });

  return socket;
};

export const getSocket = () => {
  if (!socket) throw new Error('Socket is not initialized. Call initializeSocket(userId) first.');

  return socket;
};
