
import { useEffect, useState } from "react";
import styled from "styled-components";

import './styles.css'
import { formatDate } from "../../utils/formatDate";
import { ImageComponent } from "../Image";

const UserChatCard = styled.div`
  display: flex;
  gap: 10px;

  width: 100%;

  // margin: 0 0.5rem;
  padding: 0.5rem 0.7rem;
  border-radius: 12px;

  cursor: pointer;

  background: ${(props) => props.isActive ? '#EEEEF8' : 'transparent'};

  ._ {}

  &:hover {
    background: #EEEEF8;
  }
`;

export const UserChat = ({ user, chatActive, onClick }) => {
  const { userId, username, photo, message,  createdAt } = user

  return (
    <UserChatCard isActive={chatActive === userId} onClick={onClick ? onClick : () => {}}>
      <div className={`card--img-section`}>
        <ImageComponent src={photo} alt="photo profile" />
      </div>

      <div className={`card--content-section`}>
        <div className={`name-messager`}>
          <span className="name text-dots">{username}</span>
          <span className="time">{formatDate(createdAt, 'dd/MM/yyyy', true)}</span>
        </div>

        <div className={`content-message`}>
          <p className={`message text-dots`}>{message}</p>
          {/* <span className={`new-messages`}>1</span> */}
        </div>
      </div>
    </UserChatCard>
  )
}