import axios from "../plugins/axios";
import { setHeaderToken } from "../utils/auth";

export async function GetChatList() {
  try {
    setHeaderToken()
    const request = await axios.get('chat')
    return request.data
  } catch (error) {
    return error.response.data
  }
}