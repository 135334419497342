import { format, isValid, parseISO, addDays, isBefore, formatDistanceToNow, isToday, isYesterday, differenceInDays } from 'date-fns';

export const formatDate = (date, givenFormat, timeAgo = false) => {
  // if (!timeAgo) return format(new Date(date), givenFormat)

  // const now = new Date();
  // const twoDaysAgo = addDays(now, -2);
  // const parsedDate = new Date(date);

  // if (isBefore(twoDaysAgo, parsedDate)) return formatDistanceToNow(parsedDate, { addSuffix: true });
  
  // return format(new Date(date), givenFormat)

  const parsedDate = new Date(date);

  if (!timeAgo) return format(parsedDate, givenFormat);

  const now = new Date();

  if (isToday(parsedDate)) {
    // Jika tanggal adalah hari ini, format menjadi HH:mm (jam:menit)
    return format(parsedDate, 'HH:mm');
  }

  if (isYesterday(parsedDate)) {
    // Jika tanggal adalah kemarin, tambahkan suffix "yesterday"
    return 'yesterday';
  }

  const dayDifference = differenceInDays(now, parsedDate);

  if (dayDifference > 1) {
    // Jika lebih dari satu hari yang lalu, format menjadi dd/MM/yyyy
    return format(parsedDate, 'dd/MM/yyyy');
  }

  return format(parsedDate, givenFormat); // Fallback jika kondisi tidak terpenuhi
}

export function checkValidDate(dateString) {
  const parsedDate = parseISO(dateString);
  return isValid(parsedDate);
}

export const getDateTime = (dateString) => {
  const date = new Date(dateString);

  return {
      years: date.getFullYear(),
      months: date.getMonth(),
      days: date.getDate(),
      hours: date.getHours(),
      minutes: date.getMinutes(),
      seconds: date.getSeconds()
  };
};