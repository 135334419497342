import { useContext, useEffect, useState } from "react";

import styled from "styled-components";

import AuthContext from "../components/shared/AuthContext";
import { ChatList, UserChat } from "../components";

import { GetChatList } from "../data/chat";

import { getSocket, initializeSocket } from "../config/socket";

const Container = styled.div`
  display: flex;

  background: white;
  
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);

  height: 500px;
  max-height: 650px;
  overflow: hidden;

  margin-top: 1rem;

  border-radius: 12px;
`;

const UserListContainer = styled.div`
  display: flex;
  flex-direction: column;

  padding-bottom: 1rem;
  overflow-y: auto;
  position: relative;

  width: 23%;
  max-width: 25%;

  // background: peru;

  ._{}
`;

const ChatListContainer = styled.div`
  width: 72%;
  flex: 1;
`;

const SearchInput = styled.div`
  position: sticky;
  top: 0;

  margin-top: 0.5rem;
  padding: 0.5rem 1rem;
  // background: #E5E5E5;
  // background: peru;

  ._ {}

  input {
    width: 100%;
    outline: none;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 12px;

    font-size: 1em;
  }  
`;

export function ChatPages() {
  const { user } = useContext(AuthContext)

  const [userList, setUserList] = useState([])
  const [dummyUser, setDummyUser] = useState(new Array(10).fill(0))

  const [chatActive, setChatActive] = useState(null)
  const [userActiveDetail, setUserActiveDetail] = useState(null)

  const [messageList, setMessageList] = useState([])
  const [lastMessageActiveUser, setLastMessageActiveUser] = useState({})

  const handleClick = (userId) => {
    if (!userId) return

    setChatActive(userId)

    const userActiveDetail = userList.find(item => item.userId === userId)
    if (userActiveDetail) setUserActiveDetail(userActiveDetail)
  }

  useEffect(() => {
    const fetchUserList = async() => {
      const result = await GetChatList()

      if (result.code !== 200) return alert('something went wrong')

      const data = result.data

      setUserList(data)
    }

    if (user) fetchUserList()

  }, [user])

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") setChatActive(null)
    };

    const socket = initializeSocket(user.id);
    
    const handleUserChatListUpdated = (data) => setUserList(data.res.data);

    const handleMessagesUpdated = (updatedMessages) => setMessageList(updatedMessages);

    socket.on('userChatListUpdated', handleUserChatListUpdated);
    
    if (chatActive) {
      socket.emit('getMessages', { userId1: user.id, userId2: chatActive });

      window.addEventListener("keydown", handleKeyDown);
    }

    socket.on('messagesUpdated', handleMessagesUpdated);

    return () => {
      socket.off('userChatListUpdated', handleUserChatListUpdated);
      socket.off('messagesUpdated', handleMessagesUpdated);

      window.removeEventListener("keydown", handleKeyDown);
    }
  }, [user.id, chatActive]);


  return (
    <div style={{ padding: '5rem 4rem 0'}}>
      <Container>
        <UserListContainer>
          <SearchInput>
            <input type="search" placeholder="Search a chat" />
          </SearchInput>

          {userList.length > 0
            ? userList.map((user, index) => (
              <UserChat key={index} user={user} chatActive={chatActive} onClick={() => handleClick(user.userId)} />
            ))
            : <span style={{ textAlign: 'center' }}>No Data...</span>
          }
          {userList.length > 9 && <span style={{ textAlign: 'center', fontSize: '0.8em' }}>end of list</span>}
        </UserListContainer>

        <ChatListContainer>
          {chatActive 
            ? <ChatList currentUserId={user.id} otherUserId={chatActive} userDetail={userActiveDetail} messageList={messageList} /> 
            : <div className="d-flex justify-content-center align-items-center">
                <h1>Bipi Chat Connect with others</h1>
              </div>
          }
        </ChatListContainer>
      </Container>
    </div>
  )
}