/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { ButtonModal, SearchBar, PopNotification, TopNavigation } from "./";
import AuthContext from "./shared/AuthContext";

import logo from "../assets/images/BipiLogoBlack.svg";
import defaultImageUser from "../assets/images/defaultUser.svg";

import * as notificationsData from "../data/notifications";
import { getUsersProfile } from "../data/users";

import { FaReceipt, FaPlaneUp } from "react-icons/fa6";

const NotifCount = styled.span`
  height: 18px;
  line-height: 11px;
  min-width: 18px;
  font-size: 10px;
  margin-left: 24px;
`;
const NavStyled = styled.div`
  @media only screen and (min-width: 992px) {
    .search {
      width: 22%;
      transition: width 2s;
      + .navbar-nav > .nav-item > .nav-link > .navbar-link {
        opacity: 1;
      }
      + .navbar-nav > .nav-item {
        transition: 2s;
      }
    }

    .search:hover {
      width: 100%;
      + .navbar-nav > .nav-item > .nav-link > .navbar-link {
        display: none;
        opacity: 0;
        transition: 2s;
        transition-delay: 1s;
      }
      + .navbar-nav > .nav-item > .nav-link > a {
        transition: 2s;
      }
    }
    .navbar-nav {
      align-items: center;
    }
  }
`;
const PopAlert = styled.div`
  position: absolute;
  top: 10px;
  right: 0;
  left: 0;
  margin: 50px 20px 0px;
  z-index: -1;
  // opacity: 0.9;
  // border-radius: 8px;
  // padding: 8px;
`;

const AddDropdown = styled.ul`
  list-style: none;
  padding-left: 1.2rem;

  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  ._ {}

  li {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  a {
    color: black;
    font-size: 1.1em;

    ._ {}

    &:hover {
      color: blue;
    }
  }
`;

export function Navbar(props) {
  const { toggleBottomMenuVisibility } = props;

  const navigate = useNavigate();
  const { user, logout } = useContext(AuthContext);

  const [notifications, setNotifications] = useState([]);
  const [notificationsCount, setNotificationsCount] = useState([])

  const [userProfile, setUserProfile] = useState(null);
  useEffect(() => {
    async function fetchData() {
      const notifications = await notificationsData
        .getNotifications("?limit=20")

      const response = await getUsersProfile().then((response) =>
        setUserProfile(response.data)
      );

      if (notifications.code === 200) {
        setNotifications(notifications.data)
        setNotificationsCount(notifications.data.filter((item) => !item.isRead))
      }

      return { response, notifications };
    }
    if (user) {
      fetchData();
    }
  }, [user]);

  const navigation = [
    {
      name: "Buddies",
      icon: "fa-lg fa-regular fa-user pe-2 pe-lg-0",
      href: "/contributors",
    },
    {
      name: "News",
      icon: "fa-lg fa-regular fa-newspaper pe-2 pe-lg-0",
      href: "/news",
    },
    {
      name: "Explore",
      icon: "fa-lg fa-regular fa-compass pe-2 pe-lg-0",
      href: "/articles",
    },
    {
      name: "Favorite",
      icon: "fa-lg fa-regular fa-heart pe-2 pe-lg-0",
      href: "/favorite/liked",
    },
    {
      name: "Itinerary",
      icon: "fa-lg fa-regular fa-rectangle-list pe-2 pe-lg-0",
      href: "/itineraries",
    },
    {
      name: "Notification",
      icon: "fa-lg fa-regular fa-bell pe-2 pe-lg-0",
      href: "#",
    },
    {
      name: "Add",
      icon: "fa-lg fa-regular fa-square-plus pe-2 pe-lg-0",
      href: "#",
    },
  ];
  const [isNavCollapse, setIsNavCollapse] = useState(true);
  const handleNavCollapse = () => setIsNavCollapse(!isNavCollapse);
  const [focusSearchBar, setFocusSearchBar] = useState(false);

  useEffect(() => {
    // Fungsi untuk menangani klik di luar input
    function handleClickOutside(event) {
      if (focusSearchBar && !event.target.closest('input')) {
        setFocusSearchBar(false);
      }
    }

    // Tambahkan event listener ketika komponen di-mount
    document.addEventListener('mousedown', handleClickOutside);

    // Hapus event listener ketika komponen di-unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [focusSearchBar]);

  const readNotif = async (idNotif, idArticle, isComment = false, commentId = '') => {
    await notificationsData
      .readNotification(idNotif)
      .then(() => {
        if (isComment) {
          navigate(commentId !== '' ? "/articles/" + idArticle + "?comment=" + commentId : "/articles/" + idArticle);
        } else {
          navigate("/articles/" + idArticle);
        }
      })
      .catch((error) => {
        // console.log(error.response?.data);
      });
  };

  let userImage = "";
  if (!userProfile) {
    userImage = defaultImageUser;
  } else if (
    userProfile?.traveler?.image === "" ||
    userProfile?.traveler?.image === null
  ) {
    userImage = defaultImageUser;
  } else {
    userImage = userProfile?.traveler?.image;
  }

  const filteredNavigation = navigation.filter(item => user || (item.name !== "Favorite" && item.name !== "Notification" && item.name !== "Add"));

  return (
    <div className="navbar">
      <TopNavigation toggleBottomMenuVisibility={toggleBottomMenuVisibility} />

      <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top shadow d-none d-md-block">
        <div className="container-fluid ps-lg-5 pe-lg-5">
          <a className="navbar-brand ps-3" href="/">
            <img src={logo} alt="Bipi Logo" width="50px" height="50px" />
          </a>

          <button
            className="navbar-toggler me-3 border-0"
            onClick={handleNavCollapse}
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded={!isNavCollapse ? true : false}
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
            <span className="navbar-toggler-icon"></span>
            <span className="navbar-toggler-icon"></span>
          </button>

          <NavStyled
            className={`${isNavCollapse ? "collapse" : ""} navbar-collapse`}
            id="navbarSupportedContent"
          >
            <div
              className={`me-auto ms-xl-3 mb-2 mb-lg-0 search ${
                focusSearchBar && "col"
              }`}
              onClick={() => setFocusSearchBar(true)}
            >
              <SearchBar placeholder="Where do you travel?" page={window.location.pathname.includes('/news') ? 'news' : 'article'} />
            </div>

            <ul className="ms-2 navbar-nav d-flex">
              {filteredNavigation.length > 0
                ? filteredNavigation?.map((item, index) => (
                    <li
                      className={`nav-item ${
                        ((item.name === "Notification" || item.name === "Add") && user) &&
                        "dropdown d-none d-lg-block"
                      } ${item.name === "Add" ? 'position-relative' : ''}`}
                      key={index}
                    >
                      <a
                        className={`nav-link d-flex align-items-center active ${
                          (item.name === "Notification" || item.name === "Add") &&
                          "position-relative notification dropdown-toggle"
                        }`}
                        aria-current={
                          item.name === "Notification" ? "page" : ""
                        }
                        href={item.href}
                        id={
                          item.name === "Notification" || item.name === "Add" ? "navbarDropdown" : ""
                        }
                        role={item.name === "Notification" || item.name === "Add" ? "button" : ""}
                        data-bs-toggle={
                          item.name === "Notification" || item.name === "Add" ? "dropdown" : ""
                        }
                        aria-expanded={
                          item.name === "Notification" || item.name === "Add" ? "false" : ""
                        }
                        onClick={() => setFocusSearchBar(false)}
                      >
                        {item.name === "Notification" && (
                          <NotifCount className="position-absolute top-3 start-0 p-1 translate-middle badge rounded-pill bg-danger">
                            {notificationsCount.length < 100
                              ? notificationsCount.length
                              : "99+"}
                          </NotifCount>
                        )}
                        <i className={`${item.icon} `}></i>

                        {item.name !== 'Add' && 
                          <span className={`navbar-link ${ focusSearchBar ? "d-lg-none" : "px-2"} `}>
                            {item.name}
                          </span>
                        }
                      </a>
                      {item.name === "Notification" && (
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="navbarDropdown"
                          style={{
                            width: "400px",
                            borderRadius: "5%",
                            minHeight: "200px",
                            maxHeight: "600px",
                            marginLeft: "-200px",
                          }}
                        >
                          <h5 className="pt-2 ps-3 ">Notification</h5>
                          <hr />
                          <ul
                            className="ps-0 pb-0"
                            style={{
                              overflow: "scroll",
                              maxHeight: "400px",
                              listStyleType: "none",
                            }}
                          >
                            {notifications?.length ? (
                              notifications?.map(
                                (notification, index) => (
                                  <span
                                    key={index}
                                    className="dropdown-item"
                                  >
                                    <PopNotification
                                      author="trhss"
                                      activity={notification.subject}
                                      content={notification.description}
                                      time={notification.createdAt}
                                      read={notification.isRead}
                                      type={notification.type}
                                      imageUser={
                                        notification?.userFrom?.traveler?.image
                                      }
                                      imageArticle={
                                        notification?.article?.image
                                      }
                                      readNotif={readNotif}
                                      userFrom={
                                        notification?.userFrom.traveler.name
                                      }
                                      notifId={notification.id}
                                      articleId={notification?.article?.slug}
                                      commentId={notification?.commentId}
                                    />
                                  </span>
                                )
                              )
                            ) : (
                              <p className="ps-4">No Notifications</p>
                            )}
                          </ul>
                          <a
                            className="p-3 mb-5 text-decoration-none"
                            href="/notification"
                            style={{ color: "blue", fontSize: "12px" }}
                          >
                            Show All Notification
                          </a>
                        </ul>
                      )}

                      {item.name === "Add" && (
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="navbarDropdown"
                          style={{
                            width: "200px",
                            borderRadius: "5%",
                            minHeight: "fit-content",
                            maxHeight: "300px",
                            fontSize: '0.9em',
                            top: '2rem',
                            left: '-5rem',
                          }}
                        >
                          <h5 className="pt-2 ps-3 ">What's New ?</h5>
                          <AddDropdown>
                            <li>
                              <FaReceipt width={20} height={20} />
                              <a href="/user-posts">Articles</a>
                            </li>
                            <li>
                              <FaPlaneUp width={20} height={20} />
                              <a href={`/itineraries/user/${user.username}`}>Itineraries</a>
                            </li>
                          </AddDropdown>
                        </ul>
                      )}
                    </li>
                  ))
                : ""}

              {user && (
                <>
                  <li className="nav-item d-md-block d-lg-none ">
                    <a
                      className="nav-link position-relative active"
                      href="/notification"
                    >
                      <NotifCount className="active position-absolute p-1 ms-3 translate-middle badge rounded-pill bg-danger">
                        {notificationsCount.length < 100 ? notificationsCount.length : "99+"}
                      </NotifCount>
                      <i className="fa-lg fa-regular fa-bell"></i>{" "}
                      <span className="ps-3">Notification</span>
                    </a>
                  </li>
                  <li className="nav-item d-md-block d-lg-none ">
                    <a
                      href="#" 
                      className="dropdown-toggle pe-5"
                      id="navbarDropdown"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i className="fa-lg fa-regular fa-square-plus"></i>{" "}
                      <span className="ps-3">Add</span>
                    </a>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdown">
                      <h5 className="pt-2 ps-3 ">What's New ?</h5>
                      <AddDropdown>
                        <li>
                          <FaReceipt width={20} height={20} />
                          <a href="/user-posts">Articles</a>
                        </li>
                        <li>
                          <FaPlaneUp width={20} height={20} />
                          <a href={`/itineraries/user/${user.username}`}>Itineraries</a>
                        </li>
                      </AddDropdown>
                    </ul>
                  </li>
                </>
              )}

              <li className="nav-item dropdown mt-0 ps-2">
                {!user && <ButtonModal url="#authModal" text="Sign In" styles={'mb-2'} />}
                {user && (
                  <div>
                    <span
                      className="nav-link dropdown-toggle pe-5"
                      style={{ marginLeft: "-15px" }}
                      id="navbarDropdown"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        src={userImage}
                        style={{
                          height: "35px",
                          width: "35px",
                          borderRadius: "25px",
                        }}
                        width="35px"
                        height="35px"
                        alt="user profile"
                      />
                      <p className="d-inline ms-3 d-lg-none text-decoration-none">
                        {userProfile?.data?.traveler.name}
                      </p>
                    </span>
                    <ul
                      className="dropdown-menu"
                      style={{ marginLeft: "-15px" }}
                      aria-labelledby="navbarDropdown"
                    >
                      <li>
                        <b>
                          <a
                            className="dropdown-item fw-bold"
                            href={`/user-profile/${userProfile?.username}`}
                          >
                            {userProfile?.username}
                          </a>
                        </b>
                      </li>
                      <li>
                        <a className="dropdown-item" href="/user-posts">
                          My Journey
                        </a>
                      </li>

                      <li>
                        <a className="dropdown-item" href="/statistic">
                          Insight
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="/settings">
                          Settings
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href={`/activity/logs/interaction/liked`}>
                          Activity Log
                        </a>
                      </li>
                      <li>
                        <hr className="dropdown-divider" />
                      </li>
                      <li className="d-none">
                        <span className="dropdown-item" style={{cursor: 'pointer'}}>
                          Privacy
                        </span>
                      </li>
                      <li className="d-none">
                        <span className="dropdown-item" style={{cursor: 'pointer'}}>
                          Term of Service
                        </span>
                      </li>
                      <li className="d-none">
                        <a className="dropdown-item" href="/help">
                          Help
                        </a>
                      </li>
                      <li>
                        <span
                          className="dropdown-item"
                          onClick={() => logout()}
                          style={{ cursor: 'pointer' }}
                        >
                          Sign Out
                        </span>
                      </li>
                    </ul>
                  </div>
                )}
              </li>
            </ul>
          </NavStyled>
        </div>

        {user && (userProfile && (userProfile?.email === null || !userProfile?.email?.includes('@'))) && 
          <PopAlert>
            <div className={`alert alert-danger`} role="alert">
              <div className="text-center">
                Anda belum mengisi email, isi email terlebih dahulu <a href="/settings">disini!</a>
              </div>
            </div> 
          </PopAlert>}
      </nav>
    </div>
  );
}
