import { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { SubArticleForm, UploadImageModal, CropImage } from "../components";
import "./../pages/style.css";
import Swal from "sweetalert2";
import * as articlesData from "../data/articles";
import * as usersData from "../data/users";
import jwt_decode from "jwt-decode";

import { getFileName } from "../utils/getFileName";
import blankImg from "../assets/images/404.svg";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import { Code, Strikethrough, Subscript, Superscript, Underline } from '@ckeditor/ckeditor5-basic-styles';
// import Editor from "./CkEditor/index.js";

const NameLabel = styled.label`
  font-size: 1.3em;
  padding-bottom: 0.7rem;
  &.required:after {
    content: " *";
    color: red;
  }

  @media (max-width: 768px) {
    font-size: 1em;
  }
`;
const LabelFeatured = styled.label`
  width: 100%;
  aspect-ratio: 3/2;
  border: 1px solid #aaa;
  border-radius: 8px;
  font-size: 1rem;
  overflow: hidden;
  color: white;
  font-size: 0.7rem;
  background-color: #aaa;

  &:hover {
    color: black;
  }
  @media (min-width: 768px) {
    font-size: 1rem;
  }
`;
const Button = styled.button`
  background: transparent;
  border-radius: 4px;
  border: 1px solid grey;
  margin: 0 0.5em;
  padding: 0.25em 0.75em;
  font-size: 0.9rem;
  color: grey;

  &:hover {
    background-color: white;
    color: black;
  }

  @media (max-width: 768px) {
    font-size: 0.5rem;
  }
`;
const ButtonSubArticle = styled.button`
  background: transparent;
  border-radius: 5px;
  border: 1px solid grey;
  margin: 0;
  padding: 0.25em 0.75em;
  font-size: 1.3rem;
  color: black;
  background-color: white;

  &:hover {
    color: white;
    background-color: black;
  }

  @media (max-width: 768px) {
    font-size: 0.7rem;
  }
`;

const ButtonImage = styled.label`
  width: 12vmax;
  height: 12vmax;
  border: 1px dashed grey;
  text-align: center;
  padding-top: 4vmax;
  line-height: 2em;
  font-size: 0.8rem;

  @media (max-width: 928px) {
    font-size: 0.5rem;
    padding-top: 3vmax;
  }
`;

const Tag = styled.div`
  padding: 0.5vmax 1vmax;
  color: black;
  border: 1px solid black;
  border-radius: 7px;
  margin: 0.7vmax;

`;

const Suggestions = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  border-top: 1px solid #d4d4d4;
  background-color: #f7f7f7;
  border-radius: 0 0 10px 10px;
  max-height: 200px;
  overflow: scroll;

  > li {
    list-style: none;
    cursor: pointer;
    padding: 10px;
    font-size: 12px;
  }
  li:hover {
    background-color: #d4d4d4;
  }


`;


export function PostForm({ mode, idArticle }) {
  const token = JSON.parse(localStorage.getItem("tokens"))?.data?.access_token;
  // sub article
  const [isSubArticle, setIsSubArticle] = useState(true);
  const [titleSubArticle, setTitleSubArticle] = useState("");
  const [slugSubArticle, setSlugSubArticle] = useState("");
  const [subArticleImage, setSubArticleImage] = useState();
  const [contentSubArticle, setContentSubArticle] = useState("");
  const [listSubArticle, setListSubArticle] = useState([]);
  const [typeSubArticle, setTypeSubArticle] = useState("");
  const [idEdit, setIdEdit] = useState();

  const [articleEdit, setArticleEdit] = useState();

  const [showSuggestionsTitle, setShowSuggestionsArticle] = useState(false);
  const [suggestionsArticleValue, setSuggestionsArticleValue] = useState("");
  const [selectArticleId, setSelectArticleId] = useState("");

  const draft = 0;
  const review = 1;
  const rejected = 2;
  const published = 3;


  function addSubArticle() {
    if(!selectArticleId || titleSubArticle === "" || contentSubArticle === "") return;
    setListSubArticle(currentList => [
      ...currentList,
      {
        id: selectArticleId,
        title: titleSubArticle,
        description: contentSubArticle,
      },
    ]);
    setShowSuggestionsArticle(false);
    setSelectArticleId("");
    setSuggestionsArticleValue("");
    setContentSubArticle("");
    setTitleSubArticle("");
    setTypeSubArticle("");
  }

  function saveSubArticle(id) {
    if (!selectArticleId || titleSubArticle === "" || contentSubArticle === "") return;

    const updateSubArticle = {
      id: selectArticleId,
      title: titleSubArticle,
      description: contentSubArticle,
    };

    const editIndex = listSubArticle.findIndex(function (list) {
      return list.id === id;
    });
    const updateSubArticles = [...listSubArticle];
    updateSubArticles[editIndex] = updateSubArticle;
    setListSubArticle(updateSubArticles);

    setShowSuggestionsArticle(false);
    setTitleSubArticle("");
    setSelectArticleId("");
    setContentSubArticle("");
    setSubArticleImage();
    setIdEdit();
  }

  function removeSubArticle(id) {
    const filtered = listSubArticle.filter((subArticle) => subArticle.id !== id);
    setListSubArticle(filtered);
  }

  // article gallery
  const [isArticleGallery, setIsArticleGallery] = useState();
  const [imageGalleryPreview, setImageGalleryPreview] = useState();
  const [listImageGallery, setListImageGallery] = useState([]);
  const [newImageGallery, setNewImageGallery] = useState([]);

  function addImageGallery(imageGallery) {
    setImageGalleryPreview("");
  }

  function removeImageGallery(imageGallerySlug) {
    const filtered = listImageGallery.filter((imageGallery) => imageGallery.base64 !== imageGallerySlug);
    setListImageGallery(filtered);
  }

  const [articles, setArticles] = useState();
  useEffect(() => {
    async function fetchData() {
      const response = await usersData.getUsersProfile().then((response) => setArticles(response.data.articles.published));
      return response;
    }
    fetchData();
  }, [token]);

  // Location
  const [isLocation, setIsLocation] = useState();

  const [showSuggestionsCountries, setShowSuggestionsCountries] = useState(false);
  const [showSuggestionsCity, setShowSuggestionsCities] = useState(false);
  const [suggestionsCountriesValue, setSuggestionsCountriesValue] = useState("");
  const [suggestionsCitiesValue, setSuggestionsCitiesValue] = useState("");
  // country
  const [selectCountryId, setSelectCountryId] = useState();
  const [selectCity, setSelectCity] = useState();
  const [selectCategory, setSelectCategory] = useState();
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    const getCountries = async () => {
      const queryParam = "?limit=240";
      const res = await articlesData.getCountries(queryParam);

      if (res.code !== 200) {
      }
      setCountries(() => res.data);
    };

    getCountries();
  }, []);

  const [cities, setCities] = useState([]);
  const getCities = async (idCountry) => {
    const queryParam = "?limit=6000&countryId=" + idCountry + "&search=" + suggestionsCitiesValue;
    const res = await articlesData.getCities(queryParam);

    if (res.code !== 200) {
    }
    setCities(() => res.data);
  };

  const [categories, setCategories] = useState([]);
  useEffect(() => {
    const getCategories = async () => {
      const currentUser = jwt_decode(token);
      let queryParam = "?limit=9";
      if (currentUser.is_admin) {
        queryParam += `&is_admin=${currentUser.is_admin}`
      }
      const res = await articlesData.getCategories(queryParam);

      if (res.code !== 200) {
      }
      setCategories(() => res.data);
    };

    getCategories();
  }, []);

  // label
  const [listLabel, setListLabel] = useState([]);

  const [SEODescription, setSEODescription] = useState("");
  const [SEOKeyword, setSEOKeyword] = useState([]);

  function addLabel(e, isTags = true) {
    if (e.target.value !== "") {
      if (e.key === ",") {
        if(isTags) {
          if (listLabel.includes(e.target.value)) return;
          setListLabel([...listLabel, e.target.value.trim()]);
        } else {
          if (SEOKeyword.includes(e.target.value)) return;
          setSEOKeyword([...SEOKeyword, e.target.value.trim()]);
        }
        e.target.value = "";
      }
    }
  }

  function removeLabel(labelName, isTags = true) {
    if(isTags) {
      const filtered = listLabel.filter((label) => label !== labelName);
      setListLabel(filtered);
    } else {
      const filtered = SEOKeyword.filter((keyword) => keyword !== labelName);
      setSEOKeyword(filtered);
    }
  }

  const [saved, setSaved] = useState();
  if (saved === undefined || saved === false) {
    const getSaved = async () => {
      window.onmouseleave = confirm_exit;
      window.onbeforeunload = confirm_exit;
      function confirm_exit() {
        return "Are you sure you want to exit this page? All your unsaved changes will be lost.";
      }
    };

    getSaved();
  }

  const [selectCountry, setSelectCountry] = useState();
  const [publishStatus, setPublishStatus] = useState();
  const [title, setTitle] = useState("");
  const [slug, setSlug] = useState("");
  const [content, setContent] = useState("");
  const [modeUpload, setModeUpload] = useState();
  const [featuredPreview, setFeaturedPreview] = useState();
  const [imageFeatured, setImageFeatured] = useState();
  const [placeName, setPlaceName] = useState("");
  const [placeAddress, setPlaceAddress] = useState("");
  const [placeLatitude, setPlaceLatitude] = useState("");
  const [placeLongitude, setPlaceLongitude] = useState("");
  const [subArticleImagePreview, setSubArticleImagePreview] = useState();
  const [modeUploadImage, setModeUploadImage] = useState(true);
  const [imagePopUp, setImagePopUp] = useState();
  const [imageCaption, setImageCaption] = useState("");
  const [error, setError] = useState("");

  const [isPublishSubmit, setIsPublishSubmit] = useState(false);
  const [isSaveSubmit, setIsSaveSubmit] = useState(false);
  
  const [isUpdateSubmit, setIsUpdateSubmit] = useState(false);
  const [isUpdateSaveSubmit, setIsUpdateSaveSubmit] = useState(false);

  const [imageThumbnail, setImageThumbnail] = useState();

  const [bannerImageRatio, setBannerImageRatio] = useState("")

  const validateMandatoryField = () => {
    if(!imageFeatured || imageFeatured?.base64 === "") {
      Swal.fire({
        title: "Image Empty..",
        text: "Complete the image data!",
        icon: "warning",
        confirmButtonColor: "#1c1c1c",
        confirmButtonText: "Try Again",
      });
      return false;
    }
    if(!imageFeatured || imageFeatured?.imageCaption ===  '' || imageFeatured?.imageCaption === undefined) {
      Swal.fire({
        title: "Image caption is empty..",
        text: "Fill the image caption!",
        icon: "warning",
        confirmButtonColor: "#1c1c1c",
        confirmButtonText: "Try Again",
      });
      return false;
    }

    if(!title || !slug || !content || !selectCategory) {
      Swal.fire({
        title: "Form is not valid..",
        text: "Complete the field with an asterisk(*)!",
        icon: "warning",
        confirmButtonColor: "#1c1c1c",
        confirmButtonText: "Try Again",
      });
      return false;
    }
    return true
  }

  const typeArticle = () => {
    if (selectCategory === "News") {
      return "News "
    } else {
      return "Article "
    }
  }

  const addArticle = async () => {
    const isValidate = validateMandatoryField();
    
    if(!isValidate) {
      return;
    }

    setIsPublishSubmit(true)
    Swal.showLoading();

    const newArticle = {
      countryId: selectCountryId,
      cityId: selectCity,
      category: selectCategory,
      tags: listLabel,
      title: title,
      slug: slug,
      content: content,
      image: imageFeatured,
      imageThumbnail,
      hasMap: isLocation ? isLocation : false,
      placeName: placeName,
      placeAddress: placeAddress,
      placeLatitude: placeLatitude,
      placeLongitude: placeLongitude,
      publishStatus: published,
      subArticles: listSubArticle,
      gallery: listImageGallery,
      isSubArticle: isSubArticle,
      SEODescription: SEODescription,
      SEOKeyword: SEOKeyword.join(','),
      bannerImageRatio,
      isCover: imageFeatured.isCover
    };
    
    await articlesData.storeArticle(newArticle)
      .then((response) => {
        setIsPublishSubmit(false)
        Swal.hideLoading();
        setSaved(true);
        window.onmouseleave = "";
        window.onbeforeunload = "";

        if (response.code === 201) {
          Swal.fire( typeArticle() + 'Published!', '', 'success');
          window.location.href = "/user-posts";
        } else {
          Swal.fire('An error occurred while publishing the article!', '', 'error');
        }
      })
      .catch((error) => {
        setIsPublishSubmit(false)
        Swal.hideLoading()
        if (error.response.data.data.message === "Slug is already used") {
          Swal.fire({
            title: "Publishing Failed",
            text: "Slug is already exist, change slug!",
            icon: "warning",
            confirmButtonColor: "#1c1c1c",
            confirmButtonText: "Try Again",
          });
        }else if(error.response.status === 400) {
          Swal.fire({
            title: "Publishing Failed",
            text: "complete the image data and field with an asterisk(*)!",
            icon: "warning",
            confirmButtonColor: "#1c1c1c",
            confirmButtonText: "Try Again",
          });
        }
      });
  };

  const saveArticle = async () => {
    const isValidate = validateMandatoryField();

    if(!isValidate) {
      return;
    }

    setIsSaveSubmit(true);
    Swal.showLoading();

    const newArticle = {
      countryId: selectCountryId,
      cityId: selectCity,
      // provinceId: selectProvinceId,
      category: selectCategory,
      tags: listLabel,
      title: title,
      slug: slug,
      content: content,
      image: imageFeatured,
      imageThumbnail,
      hasMap: isLocation ? isLocation : false,
      placeName: placeName,
      placeAddress: placeAddress,
      placeLatitude: placeLatitude,
      placeLongitude: placeLongitude,
      publishStatus: draft,
      subArticles: listSubArticle,
      gallery: listImageGallery,
      isSubArticle: isSubArticle,
      SEODescription: SEODescription,
      SEOKeyword: SEOKeyword.join(','),
      bannerImageRatio,
      isCover: imageFeatured.isCover
    };

    await articlesData.storeArticle(newArticle)
      .then((response) => {
        setIsSaveSubmit(false);
        Swal.hideLoading();
        setSaved(true);
        window.onmouseleave = "";
        window.onbeforeunload = "";

        if (response.code === 201) {
          Swal.fire( typeArticle() + 'Saved!', '', 'success');
          window.location.href = "/user-posts";
        } else {
          Swal.fire('An error occurred while saving the article!', '', 'error');
        }

      })
      .catch((error) => {
        setIsSaveSubmit(false);
        Swal.hideLoading();
        if (error.response.status === 400) {
          Swal.fire({
            title: "Updating Failed",
            text: "complete the image data and field with an asterisk(*)!",
            icon: "warning",
            confirmButtonColor: "#1c1c1c",
            confirmButtonText: "Try Again",
          });
        }
      });
  };

  // =============start edit=============
  if (title === undefined && mode === "edit") {
    setTitle(articleEdit?.title);
  }

  const updateArticle = async () => {
    const isValidate = validateMandatoryField();
    
    if(!isValidate) {
      return;
    }
    
    setIsUpdateSubmit(true)
    setSaved(true);
    Swal.showLoading();

    const newArticle = {
      countryId: selectCountryId,
      cityId: selectCity,
      category: selectCategory ? selectCategory : articleEdit?.category?.name,
      tags: listLabel,
      title: title ? title : articleEdit?.title,
      slug: slug ? slug : articleEdit?.slug,
      content: content ? content : articleEdit?.content,
      image: imageFeatured,
      imageThumbnail,
      hasMap: isLocation,
      placeName: placeName,
      placeAddress: placeAddress,
      placeLatitude: placeLatitude,
      placeLongitude: placeLongitude,
      publishStatus: published,
      subArticles: listSubArticle,
      gallery: listImageGallery,
      isSubArticle: isSubArticle,
      SEODescription: SEODescription,
      SEOKeyword: SEOKeyword.join(','),
      isCover: imageFeatured.isCover
    };

    const result = await articlesData.updateArticle(idArticle, newArticle)

    if (result.code !== 200) {
      setIsUpdateSubmit(false)
      Swal.hideLoading();
      setSaved(true);
      window.onmouseleave = "";
      window.onbeforeunload = "";

      return Swal.fire({
        title: "Updating Failed",
        text: result?.message,
        icon: "warning",
        confirmButtonColor: "#1c1c1c",
        confirmButtonText: "Try Again",
      });
    }

    setIsUpdateSubmit(false)
    Swal.hideLoading();
    setSaved(true);
    window.onmouseleave = "";
    window.onbeforeunload = "";

    Swal.fire(typeArticle() + 'Updated!', '', 'success')
      .then((res) => {
        if (res.isConfirmed) window.location.href = "/user-posts";
      });

      // .then((response) => {
      //   setIsUpdateSubmit(false)
      //   Swal.hideLoading();
      //   setSaved(true);
      //   window.onmouseleave = "";
      //   window.onbeforeunload = "";

      //   if (response?.code === 400) {
      //     return Swal.fire({
      //       title: "Updating Failed",
      //       text: response?.data?.message,
      //       icon: "warning",
      //       confirmButtonColor: "#1c1c1c",
      //       confirmButtonText: "Try Again",
      //     });
      //   }

      //   Swal.fire( typeArticle() + 'Updated!', '', 'success');
      //   window.location.href = "/user-posts";
      // })
      // .catch((error) => {
      //   setIsUpdateSubmit(false)
      //   Swal.hideLoading();
      //   if (error.response.status === 400) {
      //     Swal.fire({
      //       title: "Updating Failed",
      //       text: "complete the image data and field with an asterisk(*)!",
      //       icon: "warning",
      //       confirmButtonColor: "#1c1c1c",
      //       confirmButtonText: "Try Again",
      //     });
      //   }
      //   setSaved(false);
      // });
  };

  const updateSaveArticle = async () => {
    const isValidate = validateMandatoryField();
    
    if(!isValidate) {
      return;
    }

    setIsUpdateSaveSubmit(true)
    setSaved(true);
    Swal.showLoading();

    const newArticle = {
      countryId: selectCountryId,
      cityId: selectCity,
      category: selectCategory ? selectCategory : articleEdit?.category?.name,
      tags: listLabel,
      title: title ? title : articleEdit?.title,
      slug: slug ? slug : articleEdit?.slug,
      content: content ? content : articleEdit?.content,
      image: imageFeatured,
      imageThumbnail,
      hasMap: isLocation,
      placeName: placeName,
      placeAddress: placeAddress,
      placeLatitude: placeLatitude,
      placeLongitude: placeLongitude,
      publishStatus: draft,
      subArticles: listSubArticle,
      gallery: listImageGallery,
      isSubArticle: isSubArticle,
      SEODescription: SEODescription,
      SEOKeyword: SEOKeyword.join(','),
      isCover: imageFeatured.isCover
    };

    await articlesData.updateArticle(idArticle, newArticle)
      .then((response) => {
        setIsUpdateSaveSubmit(false)
        Swal.hideLoading();
        setSaved(true);
        window.onmouseleave = "";
        window.onbeforeunload = "";

        if (response?.code === 400) {
          Swal.fire({
            title: "Updating Failed",
            text: response?.data?.message,
            icon: "warning",
            confirmButtonColor: "#1c1c1c",
            confirmButtonText: "Try Again",
          });
          return;
        }
        Swal.fire(typeArticle() + 'Updated!', '', 'success');
        window.location.href = "/user-posts";
      })
      .catch((error) => {
        setIsUpdateSaveSubmit(false);
        Swal.hideLoading();
        if (error.response.status === 400) {
          Swal.fire({
            title: "Updating Failed",
            text: "complete the image data and field with an asterisk(*)!",
            icon: "warning",
            confirmButtonColor: "#1c1c1c",
            confirmButtonText: "Try Again",
          });
        }
        setSaved(false);
      });
  };

  // =============end edit=============

  const getBlobFromUrl = (myImageUrl) => {
    return new Promise((resolve, reject) => {
      let request = new XMLHttpRequest();
      request.open("GET", myImageUrl, true);
      request.responseType = "blob";
      request.onload = () => {
        resolve(request.response);
      };
      request.onerror = reject;
      request.send();
    });
  };

  const getDataFromBlob = (myBlob) => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(myBlob);
    });
  };

  const convertUrlToImageData = async (subArticle) => {
    try {
      const url = subArticle.image;
      let blob = await getBlobFromUrl(url);
      let imageData = await getDataFromBlob(blob);
      const filename = subArticle.image.substring(subArticle.image.lastIndexOf("/") + 1);
      listSubArticle.push({
        title: subArticle.title,
        slug: subArticle.slug,
        description: subArticle.content,
        image: {
          filename: "image.jpg",
          base64: imageData,
          imageCaption: subArticle.imageCaption,
        },
      });
    } catch (err) {
      return null;
    }
  };

  // if (mode === "edit" && listSubArticle.length === 0) {
  //   articleEdit?.subArticles.map((subArticle, index) => {
  //     convertUrlToImageData(subArticle);
  //   });
  // }
  const [imageBase64, setImageBase64] = useState("");
  const [imageName, setImageName] = useState("");
  const [imagePreview, setImagePreview] = useState();
  const [indexGallery, setIndexGallery] = useState();
  const [imageEdit, setImageEdit] = useState();

  // Gallery

  const convertUrlToGallery = async (gallery, isCover = false) => {
    try {
      const url = gallery.image;
      if (!url || url === '') {
        setListImageGallery(currentList => [...currentList, {
          filename: '',
          base64: '',
          imageCaption: gallery.imageCaption,
          isCover
        }])
      } else {
        let blob = await getBlobFromUrl(url);
        let imageData = await getDataFromBlob(blob);
        const filename = gallery.image.substring(gallery.image.lastIndexOf("/") + 1);
        // listImageGallery.push({
        //   filename: filename,
        //   base64: imageData,
        //   imageCaption: gallery.imageCaption,
        // });
        setListImageGallery(currentList => [...currentList, {
          filename: filename,
          base64: imageData,
          imageCaption: gallery.imageCaption,
          isCover
        }])
      }
      setIsArticleGallery(true);
    } catch (err) {
      return null;
    }
  };

  // Gallery

  const convertUrlToFeatured = async (image, imageCaption, isThumbnail = false, isCover = false) => {
    try {
      if (isThumbnail) {
        const blobThumbnail = await getBlobFromUrl(image)
        let imageData = await getDataFromBlob(blobThumbnail)
        const thumbnailName = getFileName(image)

        setImageThumbnail({
          filename: thumbnailName,
          base64: imageData,
          imageCaption,
          isCover: isCover
        })
      } else {
        const url = image;
        let blob = await getBlobFromUrl(url);
        const imageData = await getDataFromBlob(blob);
        // const filename = image.substring(image.lastIndexOf("/") + 1);
        const fileName = getFileName(image);

        setImageFeatured({
          filename: fileName,
          base64: imageData,
          imageCaption: imageCaption,
          isCover: isCover
        });
      }
    } catch (err) {
      console.log('err', err)
      return null;
    }
  };

  const suggestionsCountry = countries?.filter((countries) => countries.name.toLowerCase().includes(suggestionsCountriesValue.toLowerCase()));
  const suggestionsCity = cities?.filter((cities) => cities.countryId === selectCountryId && cities.name.toLowerCase().includes(suggestionsCitiesValue.toLowerCase()));
  const handleSuggestionCountry = (suggestion) => {
    setSuggestionsCountriesValue(suggestion.name);
    setSelectCountryId(suggestion.id);
    setShowSuggestionsCountries(false);
    setSuggestionsCitiesValue("");
    setSelectCity("");
    getCities(suggestion.id);
  };
  const handleSuggestionCity = (suggestion) => {
    setSuggestionsCitiesValue(suggestion.name);
    setSelectCity(suggestion.id);
    setShowSuggestionsCities(false);
    setPlaceLatitude(suggestion.latitude);
    setPlaceLongitude(suggestion.longitude);
  };

  const [duplicateSlug, setDuplicateSlug] = useState(true);

  const checkSlug = async (slug) => {
    await articlesData.checkSlug(slug)
      .then((res) => {
        setDuplicateSlug(res.data.status);
      })
      .catch((error) => {
        console.log({error})
      });
  };

  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
    if (validated) {
      addArticle();
    }
  };

  const parseLines = (value) => {
    return value.replace(/\n/g, "<br/>");
  };
  const parseLinesForm = (value) => {
    return value.replace("<br/>", "\n");
  };
  const parseSlug = (value) => {
    const text = value.toLowerCase().replace(/ /g, "-");
    const res = filterSlug(text);
    setSlug(res);
  };

  const filterSlug = (value) => {
    const text = value.toLowerCase().replace(/[^a-zA-Z0-9-]/g, "");
    return text
  }

  const [bootstrapAttributes, setBootstrapAttributes] = useState({});
  const [mainCover, setMainCover] = useState({})

  const handleGalleryPopup = (e, imageGallery, index) => {
    
    setBootstrapAttributes({
      'data-bs-toggle': 'modal',
      'data-bs-target': '#uploadImage',
    });

    setModeUpload("editGallery");
    setModeUploadImage(true);
    setImageCaption(imageGallery.imageCaption);
    setImagePreview(imageGallery.base64);
    setImageName(imageGallery.filename);
    setImageBase64(imageGallery.base64);
    setIndexGallery(index);
  }

  const handleRemoveGallery = (e, imageGallery) => {
    e.stopPropagation()
  
    removeImageGallery(imageGallery.base64)
  }

  useEffect(() => {
    const getArticles = async () => {
      const queryParam = "/" + idArticle;
      const res = await articlesData.getArticles(queryParam);

      if (res.code !== 200) {
      }

      setArticleEdit(res.data);
      const SEOKeyword = res.data?.SEOKeyword ? res.data.SEOKeyword.includes(',') ? res.data?.SEOKeyword.split(',') : [res.data?.SEOKeyword] : [];

      setTitle(res.data.title);
      setSlug(res.data.slug);
      setPublishStatus(res.data.publishStatus);
      setSelectCountryId(res.data?.country?.id);
      setSelectCity(res.data?.city?.id);
      setSelectCategory(res.data?.category?.name);
      setSuggestionsCitiesValue(res.data?.city?.name);
      setIsLocation(res.data?.hasMap);
      setIsSubArticle(isSubArticle);
      setContent(parseLinesForm(res.data.content));
      setIsSubArticle(res.data?.isSubArticle);
      convertUrlToFeatured(res.data.image, res.data?.imageCaption, false, res.data?.isCover);
      // convertUrlToFeatured(res.data?.thumbnail ?? '', res.data?.imageCaption, true);
      convertUrlToImageData(res.data?.subArticles);
      res.data?.articleGallery.map((gallery, index) => {
        return convertUrlToGallery(gallery, gallery.isCover);
      });
      res.data?.subArticles.map((article, index) => {
        return setListSubArticle(currentList => [
          ...currentList,
          {
            id: article.id,
            title: article.title,
            description: article.description,
          },
        ]);
      });
      res.data?.tags.map((tag) => setListLabel(currentList => [...currentList, tag.name]));
      setSEODescription(res.data?.SEODescription);
      setSEOKeyword(SEOKeyword)

      setPlaceLatitude(res.data.placeLatitude)
      setPlaceLongitude(res.data.placeLongitude)
    };

    if (mode === "edit") getArticles();
  }, [idArticle]);

  return (
    <>
      <form className="row g-3" style={{ marginBottom: '6rem' }}>
        <div className="mt-5 pt-5 justify-content-between d-flex">
          <div>
            <h1>{mode === "add" && "New Post"}</h1>
            <h1>{mode === "edit" && "Edit Post"}</h1>
          </div>
        </div>
        {mode === "add" && (
          <div className="form-check mb-2 ms-1 mt-4 form-switch">
            <input className="form-check-input" checked={!isSubArticle} onChange={() => setIsSubArticle(!isSubArticle)} type="checkbox" id="flexSwitchCheckDefault" />
            <NameLabel className="form-check-label" htmlFor="flexSwitchCheckDefault">
              <h6>This article will be classified as category articles</h6>
            </NameLabel>
          </div>
        )}

        <div className="mb-3 mt-3 d-flex flex-column">
          <NameLabel htmlFor="exampleInputEmail1" className="form-label required">
            Featured image
          </NameLabel>
          <LabelFeatured
            type="button"
            onClick={() => {
              setModeUpload("featured");
              setImageBase64(imageFeatured?.base64);
              setImageCaption(imageFeatured?.imageCaption);
              setImagePreview(imageFeatured?.base64);
              setImageEdit(articleEdit?.image);
            }}
            data-bs-toggle="modal"
            data-bs-target="#uploadImage"
            role="button"
            className={`position-relative d-flex flex-column justify-content-center }`}
          >
            {featuredPreview || articleEdit?.image ? (
              <img src={featuredPreview === undefined && mode === "edit" ? articleEdit?.image : featuredPreview} style={{ width: "100%", overflow: "hidden" }} className="position-absolute" alt="featured preview" />
            ) : (
              ""
            )}
            <div className="position-absolute text-center top-50 start-50 translate-middle">
              <i className="fa-regular fa-image fa-lg pb-2"></i>
              <div>{!featuredPreview ? "Upload Image" : "Change Image"}</div>
            </div>
          </LabelFeatured>
        </div>
        <div className="mb-3  mt-4">
          <NameLabel htmlFor="title" className="form-label required">
            Title
          </NameLabel>
          <input
            type="text"
            className="form-control required"
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
              parseSlug(e.target.value.toLowerCase());
              checkSlug(e.target.value);
              filterSlug(e.target.value);
            }}
            name="title"
            placeholder="Add title ..."
            required
          />
        </div>
        <div className="mb-3  mt-4">
          <NameLabel htmlFor="slug" className="form-label required">
            Slug
          </NameLabel>
          <input
            type="text"
            className="form-control"
            value={slug}
            onChange={(e) => {
              setSlug(filterSlug(e.target.value));
              checkSlug(e.target.value);
            }}
            name="slug"
            id="slug"
            placeholder="Add slug ..."
            required
          />
          {!duplicateSlug && <div className="text-danger">Slug already exists</div>}
        </div>
        <div className="mb-3  mt-4">
          <NameLabel htmlFor="exampleFormControlTextarea1" className="form-label fw-bold required">
            Content
          </NameLabel>

          <CKEditor
            editor={ ClassicEditor }
            data={ content }
            
            onReady={ editor => {
              editor.editing.view.change((writer) => {
                writer.setStyle(
                    "height",
                    "500px",
                    editor.editing.view.document.getRoot()
                );
                })
            }}
            onChange={(event, editor) => {
              const data = editor.getData()
              setContent(data);
            }}
            onBlur={(event, editor) => {
              // do something when CKEditor not focus
            }}
            onFocus={( event, editor) => {
              // do something when CKEdtior focused
            }}
            config={{
              heading: {
                options: [
                  { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                  { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                  { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                  { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
                  { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
                  { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
                  { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' },
                ]
              },
              ckfinder: {
                uploadUrl: 'https://example.com/ckfinder/core/connector/php/connector.php?command=QuickUpload&type=Images&responseType=json'
              },
              removePlugins: ['CKFinderUploadAdapter', 'CKFinder', 'EasyImage', 'Image', 'ImageCaption', 'ImageStyle', 'ImageToolbar', 'ImageUpload']
            }}
          />
          {/* <textarea
            type="text"
            multiline="true"
            className="form-control"
            value={mode === "edit" ? parseLinesForm(content) : content}
            onChange={(e) => {
              setContent(e.target.value);
            }}
            name="content"
            placeholder="Add content ..."
            rows="5"
            required
          ></textarea> */}
        </div>
        {!isSubArticle && (
          <div className="mb-3  mt-4">
            <NameLabel htmlFor="exampleInputEmail1" className="form-label">
              Another articles
            </NameLabel>

            <div className="mb-3 px-3 py-2" style={{ borderRadius: "8px", border: "1px solid gray", backgroundColor: "#b5b5b5" }}>
              {listSubArticle.length > 0 
                ? listSubArticle.map((subArticle, index) => (
                <div key={listSubArticle.idArticle} className="p-3 shadow-sm rounded bg-body mb-2 text-dark">
                  <SubArticleForm
                    key={listSubArticle.idArticle}
                    subArticleImage={subArticleImage}
                    subArticleImagePreview={subArticleImagePreview}
                    setModeUpload={setModeUpload}
                    subArticle={subArticle}
                    removeSubArticle={removeSubArticle}
                    titleSubArticle={titleSubArticle}
                    setTitleSubArticle={setTitleSubArticle}
                    slugSubArticle={slugSubArticle}
                    setSlugSubArticle={setSlugSubArticle}
                    contentSubArticle={contentSubArticle}
                    setContentSubArticle={setContentSubArticle}
                    typeSubArticle={typeSubArticle}
                    setTypeSubArticle={setTypeSubArticle}
                    setSubArticleImagePreview={setSubArticleImagePreview}
                    saveSubArticle={saveSubArticle}
                    idEdit={idEdit}
                    setIdEdit={setIdEdit}
                    modeUploadImage={modeUploadImage}
                    setModeUploadImage={setModeUploadImage}
                    setImagePopUp={setImagePopUp}
                    imageCaption={imageCaption}
                    setImageCaption={setImageCaption}
                    imageBase64={imageBase64}
                    setImageBase64={setImageBase64}
                    imageName={imageName}
                    setImageName={setImageName}
                    setImagePreview={setImagePreview}
                    imagePreview={imagePreview}
                    setSelectArticleId={setSelectArticleId}
                    listSubArticle={listSubArticle}
                  />
                </div>
              )) : ''}
              <h5 style={{ color: "white" }} className="pt-3">
                New Article
              </h5>
              <SubArticleForm
                setModeUpload={setModeUpload}
                removeSubArticle={removeSubArticle}
                titleSubArticle={titleSubArticle}
                setTitleSubArticle={setTitleSubArticle}
                suggestionsArticleValue={suggestionsArticleValue}
                contentSubArticle={contentSubArticle}
                setContentSubArticle={setContentSubArticle}
                typeSubArticle="new Sub Article"
                setShowSuggestionsArticle={setShowSuggestionsArticle}
                setSuggestionsArticleValue={setSuggestionsCitiesValue}
                showSuggestionsTitle={showSuggestionsTitle}
                articles={articles}
                setSelectArticleId={setSelectArticleId}
                selectArticleId={selectArticleId}
                listSubArticle={listSubArticle}
              />
              {!idEdit && 
                <div className="mb-3 d-flex justify-content-end mt-4">
                  <ButtonSubArticle type="button" required onClick={() => addSubArticle()}>
                    Add Article
                  </ButtonSubArticle>
                </div>
              }
            </div>

          </div>
        )}
        {isSubArticle && (
          <>
            <div className="mb-3 ms-2 mt-4 form-check">
              <input
                className="form-check-input me-2"
                style={{ width: "17px", height: "17px" }}
                type="checkbox"
                checked={isArticleGallery}
                onChange={() => {
                  setIsArticleGallery(!isArticleGallery);
                }}
                id="flexCheckDefault"
              />
              <NameLabel className="form-check-label" htmlFor="flexCheckDefault">
                Gallery
              </NameLabel>
            </div>
            {isArticleGallery && (
              <div className="mb-3 p-3 d-flex flex-wrap" style={{ border: "1px solid gray" }}>
                {listImageGallery.map((imageGallery, index) => (
                  <div
                    key={index + 1}
                    type="button"
                    onClick={(e) => handleGalleryPopup(e, imageGallery, index)}
                    {...bootstrapAttributes}
                    className="pe-2 pb-2 position-relative"
                  >
                    <Button type="button" modeUpload="gallery" className="position-absolute top-0 end-0" onClick={(e) => handleRemoveGallery(e, imageGallery)}>
                      &#10005;
                    </Button>
                    <img src={imageGallery?.base64 || imageGallery?.base64 !== '' ? imageGallery.base64 : blankImg } className="img-thumbnail" alt="gallery" style={{ width: "12vmax", height: "12vmax" }} />
                  </div>
                ))}
                {listImageGallery.length < 16 && (
                  <div>
                    <input type="file" id="file" style={{ display: "none" }} />
                    <ButtonImage
                      onClick={() => setModeUpload("gallery")}
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target={"#uploadImage"}
                      className="d-flex flex-column"
                    >
                      {" "}
                      <div>
                        <i className="ps-3 fa-regular fa-image pe-3 fa-lg ps-lg-3"></i>
                      </div>
                      <div>Upload Image</div>
                    </ButtonImage>
                  </div>
                )}
              </div>
            )}
            <div className="mb-3 ms-2 mt-4 form-check">
              <input
                className="form-check-input me-2"
                style={{ width: "17px", height: "17px" }}
                type="checkbox"
                checked={mode === "edit" && isLocation === undefined ? articleEdit?.hasMap : isLocation}
                onChange={() => {
                  setIsLocation(!isLocation);
                }}
                id="flexCheckDefault"
              />
              <NameLabel className=" form-check-label" htmlFor="flexCheckDefault">
                Location
              </NameLabel>
            </div>
            {isLocation && (
              <div className="mb-3 p-3" style={{ border: "1px solid gray" }}>
                <iframe
                  title="location"
                  src={`https://www.google.com/maps?q=${placeLatitude},${placeLongitude}&z=15&output=embed`}
                  // -7.215650, 112.769650
                  // src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1662873.2451231668!2d139.76981209999997!3d35.50924044999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x605d1b87f02e57e7%3A0x2e01618b22571b89!2sTokyo%2C%20Jepang!5e0!3m2!1sid!2sid!4v1672185595475!5m2!1sid!2sid"
                  width="100%"
                  height="300"
                  style={{ border: 0, marginBottom: "20px" }}
                  allowFullScreen=""
                  aria-hidden="false"
                  tabIndex="0"
                />
                <div className="mb-3  mt-4">
                  <NameLabel htmlFor="#placeName" className="form-label">
                    Place Name
                  </NameLabel>
                  <input
                    type="text"
                    className="form-control"
                    value={mode === "edit" && placeName === "" ? articleEdit.placeName : placeName}
                    onChange={(e) => setPlaceName(e.target.value)}
                    name="placeName"
                    id="placeAddress"
                    placeholder="Add name place ..."
                  />
                </div>
                <div className="mb-3  mt-4">
                  <NameLabel htmlFor="#placeAddress" className="form-label">
                    Address
                  </NameLabel>
                  <input
                    type="text"
                    className="form-control"
                    value={mode === "edit" && placeAddress === "" ? articleEdit.placeAddress : placeAddress}
                    onChange={(e) => setPlaceAddress(e.target.value)}
                    name="placeAddress"
                    id="placeAddress"
                    placeholder="Add address ..."
                  />
                </div>
                <div className="mb-3 row mt-4">
                  <div className="col">
                    <NameLabel htmlFor="#placeLatitude" className="form-label">
                      Latitude
                    </NameLabel>
                    <input
                      type="text"
                      className="form-control"
                      value={mode === "edit" && placeLatitude === "" ? articleEdit.placeLatitude : placeLatitude}
                      onChange={(e) => setPlaceLatitude(e.target.value)}
                      name="placeLatitude"
                      id="placeLatitude"
                      placeholder="Add latitude ..."
                    />
                  </div>
                  <div className="col">
                    <NameLabel htmlFor="#placeLongitude" className="form-label">
                      Longitude
                    </NameLabel>
                    <input
                      type="text"
                      className="form-control"
                      value={mode === "edit" && placeLongitude === "" ? articleEdit.placeLongitude : placeLongitude}
                      onChange={(e) => setPlaceLongitude(e.target.value)}
                      name="placeLongitude"
                      id="placeLongitude"
                      placeholder="Add Longitude ..."
                    />
                  </div>
                </div>
              </div>
            )}
          </>
        )}

        <div className="d-md-flex justify-content-between ">
          <div className="mb-3 mt-4 me-2" style={{ width: "100%" }}>
            <NameLabel className="form-label">Country</NameLabel>
            <input
              required
              className="form-control"
              placeholder="search"
              value={suggestionsCountriesValue ? suggestionsCountriesValue : articleEdit?.country?.name}
              onFocus={() => setShowSuggestionsCountries(true)}
              onChange={(e) => setSuggestionsCountriesValue(e.target.value)}
            />
            {showSuggestionsCountries && (
              <Suggestions className="suggestions">
                {suggestionsCountry.length
                  ? suggestionsCountry.map((countries, index) => (
                      <li key={countries.id} onClick={() => handleSuggestionCountry(countries)}>
                        {countries.name}
                      </li>
                    ))
                  : ""}
              </Suggestions>
            )}
          </div>
          <div className="mb-3 mt-4 me-2" style={{ width: "100%" }}>
            <NameLabel className="form-label">City</NameLabel>
            <input
              required
              className="form-control"
              placeholder="search"
              value={suggestionsCitiesValue ? suggestionsCitiesValue : ""}
              onFocus={() => setShowSuggestionsCities(true)}
              onChange={(e) => setSuggestionsCitiesValue(e.target.value)}
            />
            {showSuggestionsCity && (
              <Suggestions className="suggestions">
                {suggestionsCity.length
                  ? suggestionsCity.map((cities, index) => (
                      <li key={cities.id} onClick={() => handleSuggestionCity(cities)}>
                        {cities.name}
                      </li>
                    ))
                  : ""}
              </Suggestions>
            )}
          </div>

          <div className="mb-3 mt-4 " style={{ width: "100%" }}>
            <NameLabel className="form-label required">
              Category
            </NameLabel>
            <select
              value={mode === "edit" && selectCategory === undefined ? articleEdit?.category?.name : selectCategory}
              className="form-select w-100 "
              onChange={function (event) {
                const index = event.target.selectedIndex;
                setSelectCategory(event.target.value);
              }}
              required
            >
              <option value="">Choose</option>
              {categories.length
                ? categories.map((category, index) => (
                    <option key={category.id} value={category.name}>
                      {category.name}
                    </option>
                  ))
                : ""}
            </select>{" "}
          </div>
        </div>
        <div className="mb-3  mt-4">
          <NameLabel htmlFor="exampleInputEmail1" className="form-label">
            Tags
          </NameLabel>

          <div className="mb-0 p-3 d-flex flex-wrap gap-2" style={{ border: "1px solid gray", borderRadius: "8px"  }}>
            {listLabel.map((label, index) => (
              <Tag key={label}>
                <span className="pe-3">{label}</span>
                <label type="button" style={{ border: "none", borderRadius: "50%" }} onClick={() => removeLabel(label)}>
                  &#10005;
                </label>
              </Tag>
            ))}
            {listLabel.length < 6 && <input onKeyDown={(e) => addLabel(e)} style={{ flexGrow: "1", border: "none", outline: "none" }} type="text" placeholder="Add Topics" aria-label="Tags input input"></input>}
          </div>
          <small>Example: tokyo, traveling, food, etc</small>

          <div className="strike mb-2 mt-4">
            <span>SEO Field</span>
          </div>

          <div className="">
            <NameLabel htmlFor="seokeyword" className="form-label">
              Seo Keyword
            </NameLabel>
            <div className="mb-0 p-3 d-flex flex-wrap" style={{ border: "1px solid gray", borderRadius: "8px" }}>
              {SEOKeyword.map((keyword, index) => (
                <Tag key={keyword}>
                  <span className="pe-3">{keyword}</span>
                  <label type="button" style={{ border: "none", borderRadius: "50%" }} onClick={() => removeLabel(keyword, false)}>
                    &#10005;
                  </label>
                </Tag>
              ))}
              <input onKeyDown={(e) => addLabel(e, false)} style={{ flexGrow: "1", border: "none", outline: "none" }} type="text" placeholder="Add Keyword ..." aria-label="seo keyword input"></input>
            </div>
        </div>
          <div className="mb-2 mt-3">
            <NameLabel htmlFor="seodescription" className="form-label">
              Seo Description
            </NameLabel>
            <input
              type="text"
              className="form-control"
              value={SEODescription}
              onChange={(e) => {
                setSEODescription(e.target.value);
              }}
              name="SEO Description"
              placeholder="Add Description ..."
            />
        </div>
        </div>
        <div className="mb-3  mt-4 text-end">
          {mode === "add" ? (
            <>
              <button type="button" disabled={isSaveSubmit} className="rounded mb-2 me-3 btn btn-outline-dark btn-lg" onClick={(e) => {saveArticle(e); return false}}>
                Save
              </button>
              <button type="button" disabled={isPublishSubmit} className="rounded mb-2 btn btn-outline-success btn-lg" onClick={(e) => addArticle(e)}>
                Published
              </button>
            </>
          ) : (
            ""
          )}
          {mode === "edit" && publishStatus === draft ? (
            <>
              <button type="button" disabled={isUpdateSaveSubmit} className="rounded mb-2 me-3 btn btn-outline-dark btn-lg" onClick={(e) => updateSaveArticle(e)}>
                Save
              </button>
              <button type="button" disabled={isUpdateSubmit} className="rounded mb-2 btn btn-outline-success btn-lg" onClick={(e) => updateArticle(e)}>
                Published
              </button>
            </>
          ) : (
            ""
          )}
          {mode === "edit" && publishStatus === published ? (
            <button type="button" disabled={isUpdateSubmit} className="rounded mb-2 btn btn-outline-success btn-lg" onClick={() => updateArticle()}>
              Save & Published
            </button>
          ) : (
            ""
          )}
        </div>

      </form>
      <CropImage />

      <UploadImageModal
        modeUpload={modeUpload}
        imageGalleryPreview={imageGalleryPreview}
        addImageGallery={addImageGallery}
        setImageGalleryPreview={setImageGalleryPreview}
        setFeaturedPreview={setFeaturedPreview}
        setModeUpload={setModeUpload}
        setImageFeatured={setImageFeatured}
        imageFeatured={imageFeatured}
        setSubArticleImagePreview={setSubArticleImagePreview}
        setSubArticleImage={setSubArticleImage}
        setNewImageGallery={setNewImageGallery}
        newImageGallery={newImageGallery}
        listImageGallery={listImageGallery}
        setListImageGallery={setListImageGallery}
        modeUploadImage={modeUploadImage}
        typeSubArticle={typeSubArticle}
        idEdit={idEdit}
        imagePopUp={imagePopUp}
        imageCaption={imageCaption}
        setImageCaption={setImageCaption}
        imageBase64={imageBase64}
        setImageBase64={setImageBase64}
        imageName={imageName}
        setImageName={setImageName}
        setImagePreview={setImagePreview}
        imagePreview={imagePreview}
        indexGallery={indexGallery}
        imageEdit={imageEdit}
        imageThumbnail={imageThumbnail}
        setImageThumbnail={setImageThumbnail}
        modeForm={mode}
        setBannerImageRatio={setBannerImageRatio}
        setBootstrapAttributes={setBootstrapAttributes}
        setMainCover={setMainCover}
        mainCover={mainCover}
      />
    </>
  );
}
