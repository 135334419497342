import { useEffect, useState } from 'react';
import blankImage from '../../assets/images/404.svg'

export const ImageComponent = (props) => {
  const { src, className, alt, styles } = props
  const [imageLoadError, setImageLoadError] = useState(false);

  const handleImageLoad = () => {
    // Gambar berhasil dimuat, tidak ada kesalahan
    setImageLoadError(false);
  };

  const handleImageError = () => {
    // Terjadi kesalahan saat memuat gambar
    setImageLoadError(true);
  }

  return (
    <>
      {imageLoadError ? (
        <img src={blankImage} alt={alt} />
      ) : (
        <img
          src={src}
          alt={alt}
          className={`${className ? className : ''}`}
          onLoad={handleImageLoad}
          onError={handleImageError}
          style={styles ? styles : {}}
        />
      )}
    </>
  );
};